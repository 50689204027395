import axios from 'axios'
let pending=[];
//axios.defaults.baseURL = "http://localhost/zhfty/";
axios.defaults.baseURL = "https://kf.kefan.com/zhfty/";
//axios.defaults.baseURL = "https://testabc.kefan.com/zhfty/";
//axios.defaults.baseURL = "https://admin.kefan.com/zhfty/";
//axios.defaults.baseURL = "https://wis777.kefan.com/zhfty/";
//axios.defaults.baseURL = "https://jyzhw.jy-wisdombay.cn/zhfty/";
//xios.defaults.baseURL = "https://zhf.wis-p.cn/zhfty/";
//axios.defaults.baseURL = "https://n.imkoko.com/zhfty/";

axios.defaults.withCredentials = false
// axios.interceptors.request.use(config=>{
// 	config.cancelToken=new axios.CancelToken(cancel=>{
// 		pending.push({f:cancel})
// 	})
// 	return config
// })
axios.interceptors.response.use(res => {
	for(let p in pending){
		pending[p].f();
		pending.splice(p,1)
	}
	res.cancelToken=new axios.CancelToken(cancel=>{
		pending.push({f:cancel})
	})

	if(res.data.error=='9999'){
		alert(res.data.msg)
	}
	return res.data;
});
export default {
	path:axios.defaults.baseURL,
	breadcrumb:function(v,toobj,title){
		for(var i=0;i<v.length;i++){
			if(v[i].path==toobj.path){
				v.splice(i+1);
				break;
			}
		}
		if(i==v.length){
			v.push({'name':title?title:toobj.name,'path':toobj.fullPath})
		}
		return v;
	},
	setpath:function(path){
		axios.defaults.baseURL =path;
	},
	created:function(v){
		v.data.pusern=localStorage.getItem('pusern');
		if(localStorage.getItem('assetpusern')){
			v.data.assetpusern=localStorage.getItem('assetpusern');
		}
		v.data.backapi=1;
		v.data.sys=localStorage.getItem('sys')||'';
		
		return axios({
		
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			},
			responseType:v.responseType?v.responseType:'',
			url: v.url,
			onDownloadProgress:v.onDownloadProgress?v.onDownloadProgress:'',
			data: JSON.stringify(v.data)
		})
	},
	pageno:function(v){
		var pg=localStorage.getItem('pageno');
		var value='';
		if(pg){
			pg=JSON.parse(pg);
		}else{
			pg={};
		}
		if(v.value){
			value=v.value;
			pg[v.page]=v.value;
			localStorage.setItem('pageno',JSON.stringify(pg));
		}else{
			if(pg[v.page]){
				value=pg[v.page];
			}else{
				value='';
			}
		}
		return value;
	},
	MessageBox:{
		addEvent( obj, type, fn ) {
			if ( obj.attachEvent ) {
				obj['e'+type+fn] = fn;
				obj[type+fn] = function(){obj['e'+type+fn]( window.event );}
				obj.attachEvent( 'on'+type, obj[type+fn] );
			} else {
				obj.addEventListener( type, fn, false );
			}
		},
		removeEvent(oTarget, sEventType, fnHandler){
			if (oTarget.removeEventListener) {//非IE
				oTarget.removeEventListener(sEventType, fnHandler, false);
			}else if (oTarget.detachEvent) {//IE
				oTarget.detachEvent('on' + sEventType, fnHandler);
			}else {
				oTarget['on' + sEventType] = null;
			}
		},
		aci:-1,
		header:{length:0},
		maxindex:1000,
		_obj:[],
		_ord:[],
		close_back_fun:'',
		$$:function(v){
			return document.getElementById(v);
		},
		_doc:(document.compatMode != "BackCompat") ? document.documentElement : document.body,
		sort:function(id){console.log(id);console.log(this.aci);
			if(this._obj[this.aci].index<1000){
				if(id!=this.aci){
					for(var i=0;i<this._ord.length;i++){
						if(this._ord[i]==id){
							for(var j=i+1;j<this._ord.length;j++){
								this._ord[j-1]=this._ord[j];
							}
							this._ord[j]=id;
							break;
						}
					}
					this._obj[id].index=this._obj[this.aci].index+1;
					//this.$$('ajax'+id).style.zIndex=this._obj[id].index;
					
				}
			}
			this.$$('ajax'+id).style.zIndex=++this.maxindex;
			this.$$('ajax'+id).style.display='block';
			
			this.aci=id;
		},
		_CreateWindow:function(){
			var oab = document.createElement("div");
			oab.id = "ajax"+this.aci;
			oab.className='ajaxbox';

			oab.style.position="absolute";
			oab.style.top="0px";
			oab.style.left="0px";
			
			document.body.appendChild(oab);
		},
		Msg:function (p){
			if(p.name){
				for (var i=0;i<this._obj.length ;i++ )
				{
					if(this._obj[i]!=''){
						if(this._obj[i].name==p.name){
							this.$$('ajax'+i).style.display='block';
							this._msg(p);
							this.sort(i);
							
							return i;
						}
					}
				}
			}
			if(p.closeback){
				this.close_back_fun=p.closeback;
			}
	
			if(this.aci==-1){
				this.header.next=0;
				this._obj[0]={visible:1,index:500,pre:-1,next:''};
				this.aci=0;
			}else{
				for(i=0;i<this._obj.length;i++)
				{
					if(this._obj[i]==''){
						break;
					}
				}
				//this._obj[this.aci].next=i;
				this._obj[i]={visible:1,index:this._obj[this.aci].index+1,pre:this.aci,next:''};
				this.aci=i;
			}
			this._ord.push(this.aci);
			if(p.name){
				this._obj[this.aci].name=p.name;
			}
			
			this._CreateWindow();
			this.$$('ajax'+this.aci).style.zIndex=this._obj[this.aci].index;
			
			
			this._msg(p);
			if(p.mark==1){
				this.Mark(); 
				this._obj[this.aci].mark=1;   
			}else{
				this._obj[this.aci].mark=0; 
			}
			return this.aci;
			
		},
		_msg:function (p){
			var o=this.$$('ajax'+this.aci);
			o.style.display="block";
	
			var m="";
	
			m+="<div id=\"back_ap_div_"+this.aci+"\" style=\"\"></div>";
			m+="<div id=\"fly_message_"+this.aci+"\" data-id='"+this.aci+"' style=\"box-shadow:0 0 15px #ccc;background:#ffffff;position:absolute;top:6px;left:6px;border-radius:5px 5px 0 0;\">";
			if(p.title){
				m+="<div id=\"msg_title_"+this.aci+"\" class=\"msg_title\" data-id='"+this.aci+"'  class=\"msg_title\" style=\"font-size:16px;color:#666;border-bottom:1px solid #dadada;cursor:move;padding:0 5px 0px 20px;font-weight:bold;height:39px; line-height:39px;\">"+p.title+"</div>";
				m+="<div id=\"msg_close"+this.aci+"\" data-id='"+this.aci+"' onmouseover=\"this.style.background='#ddd'\" onmouseout=\"this.style.background='#fff'\" style=\"position:absolute;top:0px;right:0px;width:50px;height:39px;line-height:39px;cursor:pointer;background:#fff;color:#888;border-radius:0 5px 0 0 ;text-align:center;font-size:20px;\">&#215;</div>";
				if(p.min){
					m+='<div onclick="MessageBox.hid('+this.aci+')" onmousedown="event.cancelBubble=true" style="position:absolute;top:0px;right:25px;background:url(/images/sysicon.png) -213px -654px no-repeat;width:25px;height:25px;cursor:pointer;"></div>';
				}
			}
			m+="<div id=\"message_"+this.aci+"\" ></div>";
			m+="<div id=\"message_mark_"+this.aci+"\" onmousedown=\"MessageBox.sort("+this.aci+");MessageBox.msg_m_h();\" style=\"position:absolute;filter:alpha(opacity=0);-moz-opacity: 0;opacity: 0;background:#fff;display:none;\"></div>";
			m+="</div>";
			
			o.innerHTML=m;
			const that=this;
			this.addEvent(this.$$('msg_close'+this.aci),'click',function(e){
				var id=e.currentTarget.getAttribute('data-id');
				that.close(id)
				
			});
			this.addEvent(this.$$('msg_close'+this.aci),'mousedown',function(e){
				event.cancelBubble=true;
			});
			this.addEvent(this.$$('fly_message_'+this.aci),'mousedown',function(e){
				var id=e.currentTarget.getAttribute('data-id');
				that.sort(id);
			});
			this.addEvent(this.$$('msg_title_'+this.aci),'mousedown',function(e){
				var id=e.currentTarget.getAttribute('data-id');console.log('111')
				that.sort(id);
				that.msg_mark();
				that.MoveObject.down(e,that.$$('ajax'+that.aci));
				document.onmouseup=function(){that.msg_m_h();that.MoveObject.st();}
				console.log(that._obj);
			});
			this.$$('message_'+this.aci).innerHTML=p.content;
			var mw='';
			var mh='';
			if(p.width){
				mw=p.width;
			}else{
				mw=this.$$('message_'+this.aci).offsetWidth;
				this.$$('message_'+this.aci).style.width=mw+'px';
			}
			
			if(p.height){
				mh=p.height;
				this.$$('message_'+this.aci).style.height=mh+'px';
			}else{
				mh=this.$$('message_'+this.aci).offsetHeight;
			}
			this.$$('message_mark_'+this.aci).style.height=mh+'px';
			this.$$('message_mark_'+this.aci).style.width=mw+'px';
			this.$$('message_mark_'+this.aci).style.top='31px';
	
			this.$$('fly_message_'+this.aci).style.width=mw+"px";
			this.$$('back_ap_div_'+this.aci).style.width=mw+12+"px";
			if(p.title){
				this.$$('back_ap_div_'+this.aci).style.height=mh+39+14+"px";
			}else{
				this.$$('back_ap_div_'+this.aci).style.height=mh+14+"px";
			}
			var sctop=	document.documentElement.scrollTop||document.body.scrollTop;
	
			var fly_top=sctop+this._doc.clientHeight/2-o.offsetHeight/2;
			if ((this._doc.clientHeight/2-o.offsetHeight/2)<0) fly_top=sctop;
			var fly_left=this._doc.scrollLeft+this._doc.clientWidth/2-o.offsetWidth/2;
			if (fly_left<0) fly_left="0"+"px";
			o.style.top=fly_top+"px";
			o.style.left=fly_left+"px";
	
		},
		msg_mark:function(){
			this.$$('message_mark_'+this.aci).style.display='block';
		},
		msg_m_h:function(){
			this.$$('message_mark_'+this.aci).style.display='none';
		},
		Mark:function ()
		{
			if(this.$$('mark_all')){
				this.$$('mark_all').parentNode.removeChild(this.$$('mark_all'));
			}
			var mark = document.createElement("div");
			mark.id="mark_all";
			if(this.aci>-1){
				mark.style.zIndex=this._obj[this.aci].index-1;
			}else{
				mark.style.zIndex=499;
			}
			mark.style.display="block";
			mark.style.background="#000";
	
			if(this._doc.scrollWidth>this._doc.clientWidth){
				mark.style.width=this._doc.scrollWidth+"px";
			}else{
				mark.style.width=this._doc.clientWidth+"px";
			}
			if(this._doc.scrollHeight>this._doc.clientHeight){
				mark.style.height=this._doc.scrollHeight+"px";
			}else{
				mark.style.height=this._doc.clientHeight+"px";
			}
			mark.style.position="absolute";
	
			mark.style.filter="alpha";
			mark.style.top=0;
			mark.style.left=0;
			document.body.appendChild(mark);
			if(document.all){
				mark.filters.alpha.opacity=20;
			}else{
				mark.style.MozOpacity=0.2;
				mark.style.opacity=0.2;
			}
			
		},
		close:function(v){
			console.log(v);console.log(this.aci);
			console.log(this._obj);
			if(this.$$('ajax'+v)){
				//alpha.g('ajax'+v,200);
				this.$$('ajax'+v).parentNode.removeChild(this.$$('ajax'+v));
			}
			// if(this.aci==v){
			// 	if(this._obj[v].pre>=0){
			// 		this.aci=this._obj[v].pre;
			// 		this._obj[this.aci].next='';
			// 	}else{
			// 		this.aci=-1;
			// 	}
				
			// }else{
			// 	if(this._obj[v].pre!=-1){
			// 		this._obj[this._obj[v].pre].next=this._obj[v].next;
			// 	}else{
			// 		this.header.next=this._obj[v].next;
			// 	}
			// }
	
			if(this._obj[v].mark==1){
				
				var tag=-1;
				for (var i = this._ord.length-1; i >=0 ; i--) {
					if(tag>=0&&this._obj[this._ord[i]].mark==1){
						this.$$('mark_all').style.zIndex=this._obj[this._ord[i]].index-1;
						break;
					}
					if(this._ord[i]==v){
						tag=i;
					}
	
				}
				if(i<0){
					if(this.$$('mark_all')){
						this.$$('mark_all').parentNode.removeChild(this.$$('mark_all'));
					}
				}
			}
			this._obj[v]='';
			this._ord.splice(tag,1);
	
			if(this.close_back_fun){
				this.close_back_fun();
				this.close_back_fun='';
			}
		},
		hid:function (v)
		{	
			this.$$('ajax'+v).style.display='none';
			this._obj[v].visible=0;
			if(this.$$('mark_all'))
				this.$$('mark_all').parentNode.removeChild(this.$$('mark_all'));
		},
		MoveObject:{
			ac:false,
			abc:null,
			wh:[],
			temp1:0,
			temp2:0,
			x:0,y:0,
			bcfun(){},
			addEvent( obj, type, fn ,abc) {

				if ( obj.attachEvent ) {
					obj['e'+type+fn] = fn;
					obj[type+fn] = function(){obj['e'+type+fn]( window.event ).call(this,abc);}
					obj.attachEvent( 'on'+type, obj[type+fn] );
				} else {
					obj.addEventListener( type, function(e){fn(e,abc)}, false );
				}
			},
			move:function (es,mv)
			{
				
				var e=arguments[0] || window.event;
				if (mv.ac)
				{
					
					mv.abc.style.position="absolute";
					
					var l1=e.clientX+mv.temp1-mv.x,
						t1=e.clientY+mv.temp2-mv.y,
						wh=mv.wh;
					if(wh[0]!=undefined){
						if(t1<wh[0]){
							t1=wh[0];
						}
					}
					if(wh[1]!=undefined){
						if(l1>wh[1]){
							l1=wh[1];
						}
					}
					if(wh[2]!=undefined){
						if(t1>wh[2]){
							t1=wh[2];
						}
					}
					if(wh[3]!=undefined){
						if(l1<wh[3]){
							l1=wh[3];
						}
					}

					mv.abc.style.left=l1+"px";
					mv.abc.style.top=(t1<0?0:t1)+"px";
					
					if(mv.bcfun!=undefined){
						mv.bcfun([l1,t1]);
					}
		
					return false;
				}
				
			},
			down:function (es,o,fun)
			{
				document.onselectstart=new Function("event.returnValue=false");
				document.body.setAttribute('style','-moz-user-select: none;');
				var e=es || window.event;
				
						
				this.ac=true;
				this.abc=o;
				
				this.temp1=this.abc.offsetLeft,
				this.temp2=this.abc.offsetTop;
				this.x=e.clientX;
				this.y=e.clientY;
				
				this.bcfun=fun;
				this.addEvent(document,'mousemove',this.move,this);
				
				return false;
		
			},
			st:function ()
			{
				this.ac=false;
				this.wh=[];
				document.body.removeAttribute('style');
				document.onselectstart=null;
				document.onmouseup=null;
			}
		}
	}
}

